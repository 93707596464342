import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import OutlinedLabeledInput from "../../components/OutlinedLabeledInput";
import { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import BackButton from "../../components/BackButton";

interface ForgotPasswordFormData {
  phoneNo: string;
}

const emptyForgotPasswordFormData: ForgotPasswordFormData = {
  phoneNo: "",
};

export default function ForgotPasswordPage() {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [data, setData] = useState<ForgotPasswordFormData>(emptyForgotPasswordFormData);
  const [error, setError] = useState<ForgotPasswordFormData>(emptyForgotPasswordFormData);
  const { t } = useTranslation();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (data.phoneNo.trim() === "") {
      setError((prev) => ({
        ...prev,
        phoneNo: t("error.required.password"),
      }));
      return;
    }
    console.log("submitting forgot password form...");
    console.log("forgot password form submitted!");
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: isLargeScreen ? "flex-start" : "space-between",
        alignItems: "center",
        background: "#FFF",
        boxSizing: "border-box",
        gap: 5,
        px: 6,
        pt: 4,
        pb: 9.1,
        mx: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box width={"100%"}>
          <BackButton sx={{ ml: -2 }} />
        </Box>
        <Typography variant="h4" width={"100%"}>
          {t("common.forgotPassword")}
        </Typography>
        <OutlinedLabeledInput
          label={t("common.phoneNumber")}
          name="Phone No"
          type="number"
          fullWidth
          value={data.phoneNo}
          onChange={(e) => {
            if (error.phoneNo) setError((prev) => ({ ...prev, phoneNo: "" }));
            setData((prev) => ({ ...prev, phoneNo: e.target.value }));
          }}
          error={!!error.phoneNo}
          helperText={error.phoneNo}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2.5,
        }}
      >
        <Button variant="contained" type="submit" size="large">
          {t("common.getOtp")}
        </Button>
      </Box>
    </Box>
  );
}
