import axios from "axios";

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? "https://2cxhchaqv1.execute-api.eu-central-1.amazonaws.com/dev"
      : "http://localhost:3000",
  headers: {
    common: {
      "Accept-Language": localStorage.getItem("i18nextLng") || navigator.language,
    },
  },
});
