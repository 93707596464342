import { Dispatch, SetStateAction, createContext, useContext, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { Buffer } from "buffer";
import { calculateTotalPrice, OrderItem } from "../_interface/orderItem";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs, { Dayjs } from "dayjs";
import { OrderType } from "../_enum/order-type.enum";
import { useSelected } from "./LocationProvider";
import { ServiceType } from "../_enum/service-type";

interface _ {
  cart: OrderItem[];
  pickupTime: Dayjs;
  orderType: OrderType;
  newItem: boolean;
  subTotal: number;
  tip: number;
  total: number;
  setTip: Dispatch<SetStateAction<number>>;
  setNewItem: Dispatch<SetStateAction<boolean>>;
  setOrderType: Dispatch<SetStateAction<OrderType>>;
  setPickupTime: Dispatch<SetStateAction<Dayjs>>;
  addItem: (item: OrderItem) => void;
  clearCart: () => void;
  removeItem: (index: number, itemName: string) => void;
  setNewQuantity: (i: number, newQuantity: number, itemName: string) => void;
  calculateSubTotal: (cart: OrderItem[]) => number;
}

const cartContext = createContext<_>({} as _);

export function CartContextProvider({ children }: any) {
  const [cart, setCart] = useLocalStorage<OrderItem[]>("cart", []);
  const [isNewUnseenItem, setIsNewUnseenItem] = useState(false);
  const [orderType, setOrderType] = useState<OrderType>(OrderType.DineIn);
  const [pickupTime, setPickupTime] = useState<Dayjs>(dayjs().add(30, "minutes"));
  const [tip, setTip] = useState(0);
  const { selectedLocation } = useSelected();
  const subTotal = useMemo(() => calculateSubTotal(cart), [cart]);
  const total = useMemo(() => subTotal + tip, [subTotal, tip]);

  useEffect(() => {
    if (!selectedLocation) return;
    if (selectedLocation.serviceType === ServiceType.Pickup) setOrderType(OrderType.Pickup);
    else setOrderType(OrderType.DineIn);
  }, [selectedLocation]);

  function addItem(item: OrderItem) {
    item.hash = generateHash(item);
    let foundItem = cart.find((cI) => cI.hash === item.hash);
    if (foundItem) {
      let newCart = cart;
      let foundIndex = cart.findIndex((cI) => cI.hash === item.hash);
      newCart[foundIndex].quantity = foundItem.quantity + item.quantity;
      newCart[foundIndex].totalPrice = calculateTotalPrice(item.pricePerQuantity, newCart[foundIndex].quantity);
      setCart(newCart);
    } else {
      item.totalPrice = calculateTotalPrice(item.pricePerQuantity, item.quantity);
      setCart([item, ...cart]);
    }
    setIsNewUnseenItem(true);
    toast.dismiss();
    toast(`Added ${item.menuItem.name} to Cart`);
  }

  function removeItem(index: number, itemName: string) {
    let tempCart = cart;
    tempCart.splice(index, 1);
    setCart(tempCart);
    toast(`Removed ${itemName} from Card`);
  }

  function clearCart() {
    setCart([]);
  }

  function setNewQuantity(i: number, newQuantity: number, itemName: string) {
    if (newQuantity === 0) {
      removeItem(i, itemName);
      return;
    }
    let tempCart = cart;
    tempCart[i].quantity = newQuantity;
    tempCart[i].totalPrice = calculateTotalPrice(tempCart[i].pricePerQuantity, newQuantity);
    setCart(tempCart);
  }

  /**
   * Hash object does not contain quantity
   * @param item
   */
  function generateHash(item: OrderItem): string {
    let hashObj: any = {
      selectedMenuItemAddOns: item.selectedMenuItemAddOns,
      id: item.menuItem.id,
    };
    return Buffer.from(JSON.stringify(hashObj)).toString("base64");
  }

  function calculateSubTotal(cart: OrderItem[]) {
    let total = 0;
    cart.map((item) => (total += item.totalPrice));
    return total;
  }

  return (
    <>
      <cartContext.Provider
        value={{
          cart,
          pickupTime,
          subTotal,
          tip,
          total,
          orderType,
          newItem: isNewUnseenItem,
          setTip,
          setNewItem: setIsNewUnseenItem,
          setOrderType,
          setPickupTime,
          addItem,
          clearCart,
          removeItem,
          setNewQuantity,
          calculateSubTotal,
        }}
      >
        {children}
      </cartContext.Provider>
      <ToastContainer hideProgressBar closeOnClick limit={1} autoClose={500} />
    </>
  );
}

export function useCart() {
  return useContext(cartContext);
}
