import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import BackButton from "../../components/BackButton";
import { FormEvent, useState } from "react";
import { useUserAuth } from "../../context/AuthContextProvider";
import { matchIsValidTel } from "mui-tel-input";
import { useNavigate, useSearchParams } from "react-router-dom";
import TelOutlinedLabeledInput from "../../components/TelOutlinedLabeledInput";
import { ROUTES, SEARCH_PARAMS } from "../../config/constants";
import { useTranslation } from "react-i18next";

interface GuestLoginFormData {
  phoneNo: string;
}

const emptyGuestLoginFormData: GuestLoginFormData = {
  phoneNo: "",
};

export default function GuestLoginPage() {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { user, guestSignUp } = useUserAuth();
  const [data, setData] = useState<GuestLoginFormData>(emptyGuestLoginFormData);
  const [error, setError] = useState<GuestLoginFormData>(emptyGuestLoginFormData);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (user) {
    navigate(`../${ROUTES.menu}`);
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (data.phoneNo.trim() === "") {
      setError((prev) => ({
        ...prev,
        phoneNo: t("error.required.phoneNumber"),
      }));
      return;
    }
    try {
      console.log("submitting guest login form...");
      if (!matchIsValidTel(data.phoneNo)) throw Error(t("error.invalid.phoneNumber"));
      await guestSignUp(data.phoneNo);
      if (searchParams.get(SEARCH_PARAMS.redirect))
        navigate(
          `../${ROUTES.verifyOtp}?${SEARCH_PARAMS.redirect}=${searchParams.get(SEARCH_PARAMS.redirect)}&${
            SEARCH_PARAMS.process
          }=${ROUTES.guestLogin}`
        );
      else navigate(`../${ROUTES.verifyOtp}?${SEARCH_PARAMS.process}=${ROUTES.guestLogin}`);
      console.log("guest login form submitted!");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: isLargeScreen ? "flex-start" : "space-between",
        alignItems: "center",
        background: "#FFF",
        boxSizing: "border-box",
        gap: 5,
        px: 6,
        pt: 4,
        pb: 9.1,
        mx: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box width={"100%"}>
          <BackButton sx={{ ml: -2 }} />
        </Box>
        <Typography variant="h4" width={"100%"}>
          {t("common.guestLogin")}
        </Typography>
        <TelOutlinedLabeledInput
          label={t("common.phoneNumber")}
          name="Phone No"
          fullWidth
          value={data.phoneNo}
          onChange={(newValue) => {
            if (error.phoneNo) setError((prev) => ({ ...prev, phoneNo: "" }));
            setData((prev) => ({ ...prev, phoneNo: newValue }));
          }}
          error={!!error.phoneNo}
          helperText={error.phoneNo}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2.5,
        }}
      >
        <Button variant="contained" type="submit" size="large">
          {t("common.getOtp")}
        </Button>
      </Box>
    </Box>
  );
}
