import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../axios";

export default function LanguageSelector() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { i18n } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleChangeLanguage = async (e: any, code: string) => {
    await i18n.changeLanguage(code, (err) => {
      if (err) return console.log("something went wrong loading", err);
    });
    localStorage.setItem("i18nextLng", code);
    api.defaults.headers.common["Accept-Language"] = code;
    handleClosePopUp(e);
    window.location.reload();
  };

  return (
    <IconButton
      sx={{
        position: "absolute",
        right: 0,
        width: 40,
        height: 40,
        backgroundImage: `url(/${i18n.language.split("-")[0]}-flag-round-circle-icon.png)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        ":hover": {
          backgroundColor: "initial",
        },
      }}
      onClick={handleClick}
    >
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClosePopUp}
        BackdropProps={{
          sx: {
            backgroundColor: "transparent",
          },
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        {Object.keys(i18n.options.resources!).map((langCode) => (
          <MenuItem
            key={langCode}
            onClick={(e) => handleChangeLanguage(e, langCode)}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              color: i18n.language.includes(langCode) ? "primary.main" : undefined,
            }}
          >
            <img src={`/${langCode}-flag-icon.png`} alt="flag" width={24} />
            <Typography>{`${i18n.options.resources![langCode].name}`}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </IconButton>
  );
}
