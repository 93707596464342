import { Radio, RadioProps } from "@mui/material";
import { styled } from "@mui/material/styles";

const RadioIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 22,
  height: 22,
  boxShadow:
    "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.secondary.main,
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background: "rgba(206,217,224,.5)",
  },
}));

const RadioCheckedIcon = styled(RadioIcon)({
  boxShadow: "inset 0 0 0 1px #3686E5, inset 0 -1px 0 #3686E5",
  "&:before": {
    display: "block",
    width: 22,
    height: 22,
    backgroundImage: "url(/Check.svg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#3686E522",
  },
});

export default function RadioButton(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<RadioCheckedIcon />}
      icon={<RadioIcon />}
      {...props}
    />
  );
}
