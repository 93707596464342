import { Box, Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useCart } from "../context/CartProvider";

interface Props {
  index: number;
  quantity: number;
  name: string;
}

export default function CartItemQuantityButtons({
  quantity,
  index,
  name,
}: Props) {
  const { setNewQuantity } = useCart();

  return (
    <Box display={"flex"} alignItems={"center"} gap={1.5}>
      <Button
        variant="outlined"
        sx={{ minWidth: 0, p: 0.7 }}
        onClick={() => setNewQuantity(index, quantity - 1, name)}
      >
        {quantity > 1 ? (
          <RemoveIcon fontSize="small" />
        ) : (
          <DeleteForeverIcon fontSize="small" />
        )}
      </Button>
      <Typography>{quantity}</Typography>
      <Button
        variant="contained"
        sx={{ minWidth: 0, p: 0.7 }}
        onClick={() => setNewQuantity(index, quantity + 1, name)}
      >
        <AddIcon fontSize="small" />
      </Button>
    </Box>
  );
}
