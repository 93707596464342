import { Add, AddShoppingCart, Remove } from "@mui/icons-material";
import { Box, Button, FormControl, FormGroup, Grid, IconButton, Paper, RadioGroup, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BottomBar from "../../components/BottomBar";
import { useCart } from "../../context/CartProvider";
import { getMenuItemByID } from "../../services/menu-item.service";
import { MenuItem } from "../../_interface/menuItem";
import { OrderItem } from "../../_interface/orderItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioButton from "../../components/RadioButton";
import CheckBoxButton from "../../components/CheckBoxButton";
import { MenuItemAddOn } from "../../_interface/addOn";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";

interface SelectableAddOn {
  [categoryId: number]: {
    [addOnId: number]: boolean;
  };
}

const getSelectedAddOns = (tempAddOns: SelectableAddOn, addOns: MenuItemAddOn[]) => {
  const temp: MenuItemAddOn[] = [];
  for (const category in tempAddOns) {
    if (Object.prototype.hasOwnProperty.call(tempAddOns, category)) {
      for (const addOn in tempAddOns[category]) {
        if (Object.prototype.hasOwnProperty.call(tempAddOns[category], addOn)) {
          if (tempAddOns[category][addOn]) temp.push(...addOns.filter((x) => x.id === Number(addOn)));
        }
      }
    }
  }
  return temp;
};

function MenuItemDetailPage() {
  const { menuItemId } = useParams();
  const { addItem } = useCart();
  const [quantity, setQuantity] = useState<number>(1);
  const menuItemIdParsed = parseInt(menuItemId ?? "");
  const [pricePerQuantity, setpricePerQuantity] = useState<number>();
  const [totalPrice, setTotalPrice] = useState<number>();
  const [selectedMenuItemAddOns, setSelectedMenuItemAddOns] = useState<MenuItemAddOn[]>([]);
  const [tempAddOns, setTempAddOns] = useState<SelectableAddOn>({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    data: menuItem,
    isLoading,
    error,
  } = useQuery<MenuItem>(["get-menu-item", { menuItemId }], async () => await getMenuItemByID(menuItemIdParsed));

  useEffect(() => {
    let temp: SelectableAddOn = {};
    menuItem?.addOnCategories.forEach((category) => {
      temp[category.id] = {};
      category.addOns.forEach((addOn) => {
        temp[category.id][addOn.id] = false;
      });
    });
    setTempAddOns(temp);
  }, [menuItem]);

  const handleSelectChange = (categoryId: number, addOnId: number) => {
    setTempAddOns((prev) => ({
      ...prev,
      [categoryId]: {
        ...prev[categoryId],
        [addOnId]: !prev[categoryId][addOnId],
      },
    }));
  };

  const handleRadioChange = (categoryId: number, addOnId: number) => {
    setTempAddOns((prev) => {
      const x = Object.keys(prev[categoryId]);
      const y: { [id: string]: boolean } = {};
      x.forEach((id: string) => {
        y[id] = false;
      });
      return {
        ...prev,
        [categoryId]: {
          ...y,
          [addOnId]: !prev[categoryId][addOnId],
        },
      };
    });
  };

  useEffect(() => {
    if (menuItem && tempAddOns)
      setSelectedMenuItemAddOns(menuItem.addOnCategories.flatMap((c) => getSelectedAddOns(tempAddOns, c.addOns)));
  }, [tempAddOns, menuItem]);

  const toCartItem = (): OrderItem => {
    if (!quantity || !totalPrice || !pricePerQuantity || !selectedMenuItemAddOns || !menuItem)
      throw Error("Not all Attributes set to create OrderItem");
    return {
      selectedMenuItemAddOns: selectedMenuItemAddOns,
      quantity: quantity,
      pricePerQuantity: pricePerQuantity,
      totalPrice: totalPrice,
      hash: "",
      menuItem: menuItem,
    };
  };

  useEffect(() => {
    if (menuItem && selectedMenuItemAddOns) {
      let tempAddOnsCost = 0;
      selectedMenuItemAddOns.forEach((addOn) => {
        tempAddOnsCost += addOn.price;
      });
      setpricePerQuantity(menuItem.price + tempAddOnsCost);
      setTotalPrice((menuItem.price + tempAddOnsCost) * quantity);
    }
  }, [quantity, menuItem, selectedMenuItemAddOns]);

  const reduceQuantity = () => {
    if (quantity <= 1) {
      console.error(t("error.cantReduceFromOne"));
      return;
    }
    setQuantity(quantity - 1);
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleAddItem = () => {
    const orderItem = toCartItem();
    addItem(orderItem);
    navigate(-1);
  };

  if (isLoading) return <h1>Loading</h1>;
  if (error) return <h1>Error</h1>;

  if (!menuItem) return null;
  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        position: "relative",
      }}
    >
      <BackButton
        sx={{
          position: "absolute",
          top: 32,
          left: 32,
          background: "#fff",
        }}
      />
      <Grid container sx={{ pb: 20 }}>
        <Grid
          item
          xs={12}
          sx={{
            backgroundImage: `url("${menuItem.imageUrl}")`,
            backgroundRepeat: "none",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: 200,
          }}
        ></Grid>
        <Grid item xs={8} p={2} borderBottom={"2px solid"} borderColor={"secondary.main"}>
          <Typography variant="h6" component="div" color="black" fontWeight={600}>
            {menuItem.name}
          </Typography>

          <Typography color="text.secondary" fontWeight={400} lineHeight={1.2}>
            {menuItem.shortDescription}
          </Typography>
        </Grid>
        <Grid item xs={4} p={2} borderBottom={"2px solid"} borderColor={"secondary.main"}>
          <Typography variant="h6" fontWeight={600} color="primary" textAlign={"right"}>
            CHF {menuItem.price.toFixed(2)}
          </Typography>
        </Grid>
        {menuItem.addOnCategories.map((addOnCategory) => (
          <Grid item xs={12} p={2} borderBottom={"2px solid"} borderColor={"secondary.main"}>
            <Typography variant="h6" component="div" color="black" fontWeight={600}>
              {addOnCategory.name}
            </Typography>

            {addOnCategory.isMultipleAllowed ? (
              <FormGroup>
                {addOnCategory.addOns.map((addOn) => (
                  <FormControlLabel
                    control={<CheckBoxButton />}
                    value={addOn.id}
                    onChange={(e) =>
                      handleSelectChange(
                        addOnCategory.id,
                        // @ts-ignore
                        Number(e.target.value)
                      )
                    }
                    label={
                      <>
                        <Typography>{addOn.name}</Typography>
                        {addOn.price > 0 && <Typography>+ {addOn.price.toFixed(2)}</Typography>}
                      </>
                    }
                    sx={{
                      width: "100%",
                      "& > .MuiFormControlLabel-label": {
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      },
                    }}
                  />
                ))}
              </FormGroup>
            ) : (
              <FormControl sx={{ width: "100%" }}>
                <RadioGroup
                  name={addOnCategory.name}
                  onClick={(e) =>
                    // @ts-ignore
                    handleRadioChange(addOnCategory.id, Number(e.target.value))
                  }
                >
                  {addOnCategory.addOns.map((addOn) => (
                    <FormControlLabel
                      value={addOn.id}
                      checked={tempAddOns[addOnCategory.id] ? tempAddOns[addOnCategory.id][addOn.id] : false}
                      control={<RadioButton />}
                      label={
                        <>
                          <Typography>{addOn.name}</Typography>
                          {addOn.price > 0 && <Typography>+ {addOn.price.toFixed(2)}</Typography>}
                        </>
                      }
                      sx={{
                        width: "100%",
                        "& > .MuiFormControlLabel-label": {
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
          </Grid>
        ))}
      </Grid>
      <Paper
        sx={{
          position: "fixed",
          zIndex: 5,
          bottom: 0,
          left: 0,
          right: 0,
          pb: 10,
          pt: 2,
          px: 1,
        }}
      >
        <Grid container>
          <Grid item xs={1}></Grid>
          <Grid item xs={1} display="flex" justifyContent="end" alignItems="center">
            <IconButton onClick={reduceQuantity}>
              <Remove />
            </IconButton>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
            <Typography fontWeight={800}>{quantity}</Typography>
          </Grid>
          <Grid item xs={1} display="flex" justifyContent="start" alignItems="center">
            <IconButton onClick={increaseQuantity}>
              <Add />
            </IconButton>
          </Grid>

          <Grid item xs={2}></Grid>

          <Grid item xs={6}>
            <Button
              onClick={() => handleAddItem()}
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{ borderRadius: 8, width: "100%" }}
              startIcon={<AddShoppingCart />}
            >
              {totalPrice?.toFixed(2)} CHF
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <BottomBar />
    </Box>
  );
}

export default MenuItemDetailPage;
