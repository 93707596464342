import { MenuItemAddOn } from "./addOn";
import { MenuItem } from "./menuItem";

export interface OrderItem {
  menuItem: MenuItem;
  quantity: number;
  // selectedAttributes: MenuItemAttribute[];
  selectedMenuItemAddOns: MenuItemAddOn[];
  hash: string;
  pricePerQuantity: number;
  totalPrice: number;
}

export function calculateTotalPrice(
  pricePerQuantity: number,
  quantity: number
): number {
  return pricePerQuantity * quantity;
}
