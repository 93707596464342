import { Box, Button, Tooltip, Typography } from "@mui/material";
import { FormEvent, useEffect, useRef, useState } from "react";
import BackButton from "../../components/BackButton";
import CartItem from "../../components/CartItem";
import { useCart } from "../../context/CartProvider";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import StraightIcon from "@mui/icons-material/Straight";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelected } from "../../context/LocationProvider";
import dayjs from "dayjs";
import { useUserAuth } from "../../context/AuthContextProvider";
import { ROUTES, SEARCH_PARAMS } from "../../config/constants";
import BottomBar from "../../components/BottomBar";
import { ChevronRight } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

export default function CartPage() {
  const [isTimeModalOpen, setIsTimeModalOpen] = useState(false);
  const timeRef = useRef<HTMLDivElement | null>(null);
  const { cart, subTotal, pickupTime, setPickupTime } = useCart();
  const { selectedLocation } = useSelected();
  const { user } = useUserAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (pickupTime.unix() < dayjs().unix() + 1800) {
      setPickupTime(dayjs().add(30, "minutes"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      if (pickupTime.unix() < dayjs().unix() + 1800) {
        setPickupTime(dayjs().add(30, "minutes"));
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [pickupTime, setPickupTime]);

  if (!selectedLocation) return <></>;

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "grid",
        gridTemplateRows: "auto auto 1fr",
        gap: 3,
        pt: 4,
        mx: "auto",
        background: "#F5F5F5",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <Box width={"100%"} px={6}>
        <BackButton sx={{ ml: -2 }} />
      </Box>
      <Typography variant="h4" width={"100%"} px={6}>
        {t("common.cart")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflow: "auto",
          pb: user ? 30.5 : 34,
        }}
      >
        {cart.map(({ menuItem, selectedMenuItemAddOns, quantity, totalPrice }, index) => (
          <CartItem
            key={menuItem.id}
            item={menuItem}
            addOns={selectedMenuItemAddOns}
            quantity={quantity}
            totalPrice={totalPrice}
            index={index}
          />
        ))}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 1.5,
          borderTop: "1px solid",
          borderColor: "#2a9ef757",
          pt: 2,
          pb: user ? 9.1 : 8,
          px: 6,
          position: "absolute",
          bottom: 0,
          background: "#F5F5F5",
        }}
      >
        {selectedLocation.settings.isPickupTimeEnabled && (
          <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
              <Box display={"flex"} alignItems={"center"} gap={0.7} fontSize={14}>
                <Typography fontSize={"inherit"} fontWeight={"bold"}>
                  {t("common.pickUpTime")}
                </Typography>
                <Tooltip title={"pickup time"} enterTouchDelay={0}>
                  <InfoIcon fontSize="inherit" color="primary" />
                </Tooltip>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                gap={0.7}
                onClick={() => {
                  if (!isTimeModalOpen) {
                    setIsTimeModalOpen(true);
                    timeRef.current?.click();
                  }
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["TimePicker"]} sx={{ pt: 0 }}>
                    <TimePicker
                      ref={timeRef}
                      value={pickupTime}
                      onChange={(newValue) => setPickupTime(newValue!)}
                      disablePast={true}
                      ampm={false}
                      sx={{
                        display: "none",
                      }}
                      onClose={() => setIsTimeModalOpen(false)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                <Typography fontSize={11} color={"primary.main"} textAlign={"right"}>
                  {t("common.clickToAdjustTime")}
                </Typography>
                <StraightIcon
                  fontSize={"small"}
                  color="primary"
                  sx={{
                    transform: "rotate(90deg)",
                  }}
                />
                <Typography fontSize={16} fontWeight={600}>
                  {pickupTime.hour().toString().padStart(2, "0")}:{pickupTime.minute().toString().padStart(2, "0")}
                </Typography>
              </Box>
            </Box>
            <Box width={"100%"} borderTop={"1px solid"} borderColor={"#2a9ef757"}></Box>
          </>
        )}
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} mb={0.5}>
          <Typography variant="h5" fontWeight={600}>
            {t("common.total")}
          </Typography>
          <Typography variant="h5" fontWeight={600}>
            CHF {subTotal.toFixed(2)}
          </Typography>
        </Box>
        <Button
          variant="contained"
          type="submit"
          size="large"
          sx={{
            maxHeight: 45,
          }}
          onClick={() =>
            // if logged in go to payment mode page.
            // if not logged in go to order process page followed by auth page followed with redirection to cart page on successful login.
            user
              ? navigate(`../${ROUTES.tip}`)
              : navigate(`../${ROUTES.process}?${SEARCH_PARAMS.redirect}=${ROUTES.cart}`)
          }
        >
          {t("common.continue")}
        </Button>
        {!user && (
          <Button
            variant="text"
            color="info"
            onClick={() => navigate(`../${ROUTES.guestLogin}?${SEARCH_PARAMS.redirect}=${ROUTES.tip}`)}
            // TODO: Ask Arsalan to ask client if they want to show fast checkout when user is logged in.
            // onClick={() =>
            // // if logged in go to payment mode page.
            // // if not logged in go to guest login page followed with redirection to payment mode page on successful login.
            //   user
            //     ? navigate(`../${ROUTES.paymentMode}`)
            //     : navigate(`../${ROUTES.guestLogin}?${SEARCH_PARAMS.redirect}=${ROUTES.cart}`)
            // }
            fullWidth
            sx={{ borderRadius: 8, mt: -1 }}
            endIcon={<ChevronRight />}
          >
            {t("common.fastCheckOut")}
          </Button>
        )}
      </Box>
      <BottomBar />
    </Box>
  );
}
