import { FormControl, Typography } from "@mui/material";
import { MuiTelInput, MuiTelInputProps } from "mui-tel-input";

export default function TelOutlinedLabeledInput(props: MuiTelInputProps) {
  return (
    <FormControl fullWidth={props.fullWidth} sx={{ position: "relative" }}>
      {props.label && (
        <Typography color={props.error ? "error" : "#9796A1"} mb={0.6}>
          {props.label}
        </Typography>
      )}
      <MuiTelInput fullWidth {...props} label="" />
      {props.helperText && (
        <Typography
          color={"error"}
          fontSize={12}
          position={"absolute"}
          top={"100%"}
        >
          {props.helperText}
        </Typography>
      )}
    </FormControl>
  );
}
