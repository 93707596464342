import { createContext, useContext, useEffect } from "react";
import { useLocalStorage } from "usehooks-ts";

import { useNavigate } from "react-router-dom";
import { Location } from "../_interface/location";
import { getLocationById } from "../services/location.service";

const selectedContext = createContext<{
  selectedClientId?: number;
  updateSelectedClientId: (newSelectedClientId: number) => void;
  selectedLocationId?: number;
  selectedLocation?: Location;
  updateSelectedLocationId: (newSelectedLocationId: number) => void;
  locationNavigate: (suffix: string) => void;
}>({
  updateSelectedClientId: (newSelectedClientId: number): void => {
    throw new Error("Function not implemented.");
  },
  updateSelectedLocationId: (newSelectedLocationId: number): void => {
    throw new Error("Function not implemented.");
  },
  locationNavigate: (suffix: string): void => {
    throw new Error("Function not implemented.");
  },
});

export function SelectedProvider({ children }: any) {
  const [selectedLocationId, setSelectedLocationId] = useLocalStorage<
    number | undefined
  >("selectedLocationId", undefined);
  const [selectedLocation, setSelectedLocation] = useLocalStorage<
    Location | undefined
  >("selectedLocation", undefined);
  const [selectedClientId, setSelectedClientId] = useLocalStorage<
    number | undefined
  >("client", undefined);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedLocationId) {
      getLocationById(selectedLocationId).then((location) =>
        setSelectedLocation(location)
      );
    }
  }, [selectedLocationId, setSelectedLocation]);

  const locationNavigate = (suffix: string) => {
    navigate(`${selectedClientId}/${selectedLocationId}${suffix}`);
  };

  const updateSelectedClientId = (newSelectedClientId: number): void => {
    if (newSelectedClientId !== undefined) {
      setSelectedClientId(newSelectedClientId);
    } else {
      throw Error("New Selected Client Id cannot be undefined");
    }
  };

  const updateSelectedLocationId = (newSelectedLocationId: number): void => {
    if (newSelectedLocationId !== undefined) {
      setSelectedLocationId(newSelectedLocationId);
    } else {
      throw Error("New Selected Location Id cannot be undefined");
    }
  };

  return (
    <selectedContext.Provider
      value={{
        selectedClientId,
        updateSelectedClientId,
        selectedLocationId,
        selectedLocation,
        updateSelectedLocationId,
        locationNavigate,
      }}
    >
      {children}
    </selectedContext.Provider>
  );
}

export function useSelected() {
  return useContext(selectedContext);
}
