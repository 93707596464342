import { Accordion, AccordionDetails, AccordionSummary, Alert, Avatar, Box, Typography } from "@mui/material";
import { useQuery } from "react-query";
import BottomBar from "../../components/BottomBar";
import { useUserAuth } from "../../context/AuthContextProvider";
import { getOrderByUser } from "../../services/order.service";
import { Order } from "../../_interface/order";
import { Logout } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";

function sortOrderByTime(orders: Order[]): Order[] {
  return orders.sort((a, b) => {
    const dateA = new Date(a.order_created);
    const dateB = new Date(b.order_created);

    if (dateA.getTime() && dateB.getTime()) {
      return dateB.getTime() - dateA.getTime();
    }
    return 0;
  })
};

function ProfilePage() {
  const { user, signOutUser } = useUserAuth();

  const navigate = useNavigate();
  const { t } = useTranslation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data } = useQuery<Order[]>([`get-orders-by-user/${user?.uid}`, { uid: user?.uid }], async () => {
    if (user?.uid) {
      return await getOrderByUser(user.uid);
    } else {
      throw new Error("UID not set");
    }
  });

  const signOut = () => {
    signOutUser();
    navigate(-1);
  };

  console.log(user);

  if (!user) {
    return <Alert severity="error">Not Signed In</Alert>;
  }

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        gap: 3,
        pt: 4,
        px: 4,
        mx: "auto",
        background: "#F5F5F5",
        boxSizing: "border-box",
        position: "relative",
      }}
      overflow={"auto"}
    >
      <Box width={"100%"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <BackButton />
        <Logout color="primary" onClick={() => signOut()} sx={{ cursor: "pointer" }} />
      </Box>
      <Typography variant="h4" width={"100%"}>
        {t("common.profile")}
      </Typography>
      <Avatar
        src={user.photoURL ?? undefined}
        alt="dp"
        sx={{
          width: 80,
          height: 80,
        }}
      />
      <Box display={"flex"} flexDirection={"column"} gap={0.2} color={"#8C8A9D"}>
        <Typography width={"100%"} fontSize={14} textTransform={"capitalize"}>
          {t("profile.loggedInAs")}:
        </Typography>
        <Typography width={"100%"} color={"primary.main"} fontWeight={600} fontSize={18} textTransform={"capitalize"}>
          {user.displayName}
        </Typography>
       {user.phoneNumber && <Typography width={"100%"} fontSize={16}>
          Phone Number: {user.phoneNumber} 
        </Typography>}
        {user.email && <Typography width={"100%"} fontSize={16}>
          Email: {user.email}
        </Typography>}
      </Box>
      <Typography variant="h5" width={"100%"} fontWeight={600}>
        {t("Orders")}
      </Typography>
      <Box display={"flex"} flexDirection={"column"} gap={2} mb={12}>
        {data && sortOrderByTime(data)
          .map((order) => (
            <Accordion key={order.id} TransitionProps={{ unmountOnExit: true }} color="#8C8A9D">
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6" fontWeight={600}>
                  Order #{order.id}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  {order.orderItems?.length > 0
                    && order.orderItems.map((orderItem) => (
                      <Box key={orderItem.hash} display={"grid"} gridTemplateColumns={"50px 1fr"} gap={2}>
                        <img
                          src={orderItem.menuItem.imageUrl}
                          alt={orderItem.menuItem.name}
                          width={"100%"}
                          height={"100%"}
                          style={{
                            minWidth: "100%",
                            aspectRatio: "1 / 1",
                            borderRadius: "8px",
                          }}
                        />
                        <Box>
                          <Typography fontWeight={600} mb={0.5}>
                            {orderItem.menuItem.name}
                          </Typography>
                          {orderItem.selectedMenuItemAddOns.length > 0 && (
                            <Typography fontSize={14}>Add-ons:</Typography>
                          )}
                          {orderItem.selectedMenuItemAddOns.map((addOn) => (
                            <Typography key={addOn.id} fontSize={14}>
                              {addOn.name}
                            </Typography>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  <Box width={"100%"}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                      <Typography>Order type</Typography>
                      <Typography>{order.orderType}</Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                      <Typography>Order date/time</Typography>
                      <Typography>{dayjs(order.order_created).format("HH:mm - YY.MM.DD")}</Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                      <Typography>Order status</Typography>
                      <Typography>{order.status}</Typography>
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
      </Box>
      <BottomBar />
    </Box>
  );
}

export default ProfilePage;
