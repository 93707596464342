import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  Typography,
} from "@mui/material";
import { MouseEvent, useState } from "react";

interface OutlinedInputWithErrorMessageProps extends OutlinedInputProps {
  helperText?: string;
}

export default function OutlinedLabeledInput(
  props: OutlinedInputWithErrorMessageProps
) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseUpPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  return (
    <FormControl fullWidth={props.fullWidth} sx={{ position: "relative" }}>
      {props.label && (
        <Typography color={props.error ? "error" : "#9796A1"} mb={0.6}>
          {props.label}
        </Typography>
      )}
      <OutlinedInput
        endAdornment={
          props.type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                onMouseUp={handleMouseUpPassword}
                edge="end"
                size="small"
              >
                {showPassword ? (
                  <VisibilityOff fontSize="small" sx={{ color: "#9796A1" }} />
                ) : (
                  <Visibility fontSize="small" sx={{ color: "#9796A1" }} />
                )}
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
        {...props}
        label=""
        type={
          props.type !== "password"
            ? props.type
            : showPassword
            ? "text"
            : "password"
        }
      />
      {props.helperText && (
        <Typography
          color={"error"}
          fontSize={12}
          position={"absolute"}
          top={"100%"}
        >
          {props.helperText}
        </Typography>
      )}
    </FormControl>
  );
}
