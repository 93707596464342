import {
  ConfirmationResult,
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  updateProfile,
  User,
  PhoneAuthProvider,
  linkWithCredential,
  signInWithCredential,
  sendEmailVerification,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState, useCallback, ReactNode } from "react";
import { api } from "../axios";
import { auth } from "../firebase";
import { ROUTES } from "../config/constants";

type UserContextType = {
  user: User | null;
  userPhoneNumber?: string;
  signInWithMail: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, firstName: string, lastName: string, phoneNumber: string) => Promise<void>;
  signOutUser: () => Promise<void>;
  guestSignUp: (number: string) => Promise<void>;
  verifyOTP: (otp: string, process: string) => Promise<void>;
}

const userAuthContext = createContext<UserContextType>({
  user: null,
  signInWithMail: async () => {},  
  signUp: async () => {},          
  signOutUser: async () => {},     
  guestSignUp: async () => {},     
  verifyOTP: async () => {}
});

export function UserAuthContextProvider({ children }: { children: ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [otpResult, setOtpResult] = useState<ConfirmationResult>();
  const [userPhoneNumber, setUserPhoneNumber] = useState<string>("");
  const [verifier, setVerifier] = useState<RecaptchaVerifier>();

  const setJwtToken = useCallback(async (user: User) => {
    const jwt = await user.getIdToken(true);
    api.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
  }, []);

  const setUpRecaptcha = useCallback((number: string) => {
    let recaptchaVerifier = undefined;
    if (verifier) {
      recaptchaVerifier = verifier;
    } else {
      recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", { size: "invisible" }, auth);
      recaptchaVerifier.render();
      setVerifier(recaptchaVerifier);
    }
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }, [verifier]);

  const signUp = useCallback(
    async (email: string, password: string, firstName: string, lastName: string, phoneNumber: string) => {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(user);
      await updateProfile(user, {
        displayName: `${firstName} ${lastName}`,
      });
      const result = await setUpRecaptcha(phoneNumber);
      setOtpResult(result);
      setUserPhoneNumber(phoneNumber);
      await setJwtToken(user);
      setUser(user);
    },
    [setOtpResult, setUserPhoneNumber, setUpRecaptcha, setUser, setJwtToken]
  );

  const signInWithMail = useCallback(
    async (email: string, password: string) => {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      await setJwtToken(user);
      setUser(user);
    },
    [setJwtToken, setUser]
  );

  const guestSignUp = useCallback(
    async (number: string) => {
      const result = await setUpRecaptcha(number);
      setOtpResult(result);
      setUserPhoneNumber(number);
    },
    [setUpRecaptcha, setOtpResult, setUserPhoneNumber]
  );

  const verifyOTP = useCallback(
    async (otp: string, process: string) => {
      if (!otpResult) throw Error("Get OTP first.");
      const phoneCredential = PhoneAuthProvider.credential(otpResult.verificationId, otp);

      if (process === ROUTES.signUp && user) {
        try {
          await linkWithCredential(user, phoneCredential);
          await user.reload()
        } catch (error) {
          console.error("Failed to link phone number:", error);
        }
      } else {
        console.log("No user to link.");
        await signInWithCredential(auth, phoneCredential);
        await user?.reload();
      }
    },
    [otpResult, user]
  );

  const signOutUser = useCallback(async () => {
    await auth.signOut();
    delete api.defaults.headers.common["Authorization"];
  }, []);

  const handleTokenExpiration = useCallback(async () => {
    await signOutUser();
  }, [signOutUser]);

  const refreshToken = useCallback(async () => {
    if (auth.currentUser) {
      try {
        console.log(auth.currentUser, user);
        await setJwtToken(auth.currentUser);
      } catch (error: any) {
        if (error.code === "auth/id-token-expired") {
          await handleTokenExpiration();
        } else {
          console.error("Error refreshing token:", error);
        }
      }
    }
  }, [handleTokenExpiration, setJwtToken, user]);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        try {
          // User is signed in
          await setJwtToken(currentUser);
          setUser(currentUser);
        } catch (error) {
          console.log(error);
        }
      } else {
        // User is signed out
        setUser(null);
        delete api.defaults.headers.common["Authorization"];
      }
    });
    return () => unsubscribe();
  }, [setJwtToken]);

  useEffect(() => {
    const handleUserInteraction = async () => {
      try {
        await refreshToken();
      } catch (error: any) {
        if (error.code === "auth/id-token-expired") {
          await handleTokenExpiration();
        } else {
          console.error("Error handling user interaction:", error);
        }
      }
    };

    // List of events to detect user interaction
    const events = ["mousedown", "mousemove", "keydown", "scroll"];
    events.forEach((event) => window.addEventListener(event, handleUserInteraction, { once: true }));

    // Cleanup the event listeners
    return () => {
      events.forEach((event) => window.removeEventListener(event, handleUserInteraction));
    };
  }, [refreshToken, handleTokenExpiration]);

  return (
    <userAuthContext.Provider
      value={{
        user,
        userPhoneNumber,
        signInWithMail,
        signUp,
        signOutUser,
        guestSignUp,
        verifyOTP
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
