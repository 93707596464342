import { useEffect } from "react";
import { useRoutes } from "react-router-dom";
import "./App.css";
import NotFoundPage from "./pages/NotFoundPage";
import OrderProcessPage from "./pages/Payment/OrderProcessPage";
import ProfilePage from "./pages/UserManagement/ProfilePage";
import SelectionPage from "./pages/Shop/SelectionPage";
import OrderPaymentModePage from "./pages/Payment/OrderPaymentModePage";
import MenuItemDetailPage from "./pages/Shop/MenuItemDetailPage";
import Body from "./Body";
import AuthPage from "./pages/UserManagement/AuthPage";
import SignUpPage from "./pages/UserManagement/SignUpPage";
import SignInPage from "./pages/UserManagement/SignInPage";
import { ROUTES } from "./config/constants";

import ForgotPasswordPage from "./pages/UserManagement/ForgotPassword";
import GuestLoginPage from "./pages/UserManagement/GuestLogin";
import VerifyOtpPage from "./pages/UserManagement/VerifyOtpPage";
import CartPage from "./pages/Shop/CartPage";
import TipPage from "./pages/Payment/TipPage";
import { useTranslation } from "react-i18next";
import PaymentSuccessPage from "./pages/Payment/PaymentSuccessPage";
import PaymentFailedPage from "./pages/Payment/PaymentFailedPage";
import PaymentCanceledPage from "./pages/Payment/PaymentCanceledPage";

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.title = "Fastlane";
  }, []);

  useEffect(() => {
    const setLanguage = async () => {
      const savedLng = localStorage.getItem("i18nextLng");
      let lngToUse = savedLng || navigator.language;
      if (Object.keys(i18n.options.resources!).includes(lngToUse.split("-")[0])) {
        await i18n.changeLanguage(lngToUse);
      }
    };
    setLanguage();
  }, [i18n]);

  const routes = useRoutes([
    {
      path: `${ROUTES.clientId}/${ROUTES.locationId}`,
      element: <Body />,
      children: [
        {
          path: ROUTES.home,
          element: <SelectionPage />,
        },
        {
          path: ROUTES.menuItemId,
          element: <MenuItemDetailPage />,
        },
        {
          path: ROUTES.process,
          element: <OrderProcessPage />,
        },
        {
          path: ROUTES.auth,
          element: <AuthPage />,
        },
        {
          path: ROUTES.signUp,
          element: <SignUpPage />,
        },
        {
          path: ROUTES.signIn,
          element: <SignInPage />,
        },
        {
          path: ROUTES.forgotPassword,
          element: <ForgotPasswordPage />,
        },
        {
          path: ROUTES.guestLogin,
          element: <GuestLoginPage />,
        },
        {
          path: ROUTES.verifyOtp,
          element: <VerifyOtpPage />,
        },
        {
          path: ROUTES.cart,
          element: <CartPage />,
        },
        {
          path: ROUTES.paymentMode,
          element: <OrderPaymentModePage />,
        },
        {
          path: ROUTES.profile,
          element: <ProfilePage />,
        },
        {
          path: ROUTES.tip,
          element: <TipPage />,
        },
        {
          path: ROUTES.success,
          element: <PaymentSuccessPage />,
        },
        {
          path: ROUTES.canceled,
          element: <PaymentCanceledPage />,
        },
        {
          path: ROUTES.failed,
          element: <PaymentFailedPage />,
        },
      ],
    },
    { path: "*", element: <NotFoundPage /> },
  ]);
  return <>{routes}</>;
}

export default App;
