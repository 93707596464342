import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <Grid container sx={{ height: "100%", minHeight: "100%" }}>
      <Grid item xs={12}>
        <img src="/NotFound.jpg" alt="Not found" />
      </Grid>
      <Grid item xs={12}>
        <Typography align="center" fontWeight={600}>
          {`${t("error.siteNotFound")}`}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default NotFoundPage;
