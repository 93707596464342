import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserAuthContextProvider } from "./context/AuthContextProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { CartContextProvider } from "./context/CartProvider";
import { ThemeProvider } from "@mui/material";
import { SelectedProvider } from "./context/LocationProvider";
import { theme } from "./config/theme";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={new QueryClient()}>
          <UserAuthContextProvider>
            <SelectedProvider>
              <CartContextProvider>
                <App />
                {/** must be placed here for firebase authentication */}
                <div id={"recaptcha-container"} />
              </CartContextProvider>
            </SelectedProvider>
          </UserAuthContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
