import { Button, ButtonProps } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";

export default function BackButton(props: ButtonProps) {
  const navigate = useNavigate();
  return (
    <Button
      variant="outlined"
      onClick={() => navigate(-1)}
      {...props}
      sx={{
        minWidth: 0,
        py: "5px",
        px: "5px",
        borderRadius: 0.5,
        ...props.sx,
      }}
    >
      <ArrowBackIosNewIcon fontSize={"small"} />
    </Button>
  );
}
