import { createTheme, ThemeOptions, Shadows } from "@mui/material";
const defaultTheme = createTheme();
const defaultShadows: ThemeOptions["shadows"] = [...defaultTheme.shadows];

export const theme: ThemeOptions = createTheme({
  typography: {
    fontFamily: ["Satoshi", "sans-serif"].join(","),
  },
  shape: {
    borderRadius: 16,
  },
  shadows: defaultShadows.map(() => "none") as Shadows,
  palette: {
    primary: {
      main: "#0085EB",
      light: "#2a9ef7",
    },
    secondary: {
      main: "#f2f2f2",
    },
    info: {
      main: "#2a9ef7",
      contrastText: "#ffffff",
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
        colorSecondary: {
          color: "#29323B",
          borderColor: "#e3e1e1",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: (props) => {
          return props.ownerState.size === "large"
            ? {
                borderRadius: 30,
                padding: "14px 22px",
                width: props.ownerState.fullWidth ? "100%" : 240,
              }
            : {
                borderRadius: 30,
              };
        },
      },
      defaultProps: {
        // disableRipple: true,
        disableElevation: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          ":last-child": {
            paddingBottom: "8px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          "&>textarea": {
            padding: 0,
          },
        },
        input: {
          padding: 18,
        },
        notchedOutline: {
          borderColor: "#eee",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          color: "#8C8A9D",
          borderRadius: 16,
          border: "1px solid #E8E8E8",
          ":before": {
            display: "none",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
        content: {
          margin: "16px 0",
        },
      },
    },
  },
});
