import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SEARCH_PARAMS } from "../../config/constants";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { checkPaymentAndUpdateStatusIfSuccessful } from "../../services/order.service";

function PaymentSuccessPage() {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const [updatedStatus, setUpdatedStatus] = useState<boolean>(false);

  useEffect(() => {
    if (!updatedStatus) {
      const parsedOrderId = params.orderId ? Number.parseInt(params.orderId) : undefined;
      if (parsedOrderId && !Number.isNaN(parsedOrderId)) {
        checkPaymentAndUpdateStatusIfSuccessful(parsedOrderId)
          .finally(() => setUpdatedStatus(true));
      }      
    }
  }, [updatedStatus, params.orderId])

  
  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 5,
        px: 6,
        pt: 4,
        pb: 9.1,
        mx: "auto",
        background: "#FFF",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <img src="/success.png" alt="success" />
        <Typography color={"primary.main"} fontSize={20} fontWeight={600} fontStyle={"italic"} textAlign={"center"}>
          {searchParams.get(SEARCH_PARAMS.demo) && `${t("common.demo")} `}
          {t("success.orderPlaced")}
        </Typography>
      </Box>
      <Button
        variant="contained"
        size="large"
        sx={{
          position: "absolute",
          bottom: 0,
          mb: 9.1,
        }}
        onClick={() => navigate("../")}
      >
        {t("success.menuPage")}
      </Button>
    </Box>
  );
}

export default PaymentSuccessPage;
