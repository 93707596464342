import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { MenuItem } from "../_interface/menuItem";
import { Box, Button, Chip } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useCart } from "../context/CartProvider";
import { menuItemToOrderItem } from "../services/menu-item.service";
import { useSelected } from "../context/LocationProvider";
import { useMemo } from "react";
import { ReactComponent as GlutenFree } from "../components/Tags/GlutenFree.svg";
import { ReactComponent as Vegan } from "../components/Tags/Vegan.svg";
import { ReactComponent as Spicy } from "../components/Tags/Spicy.svg";
import { ReactComponent as Milk } from "../components/Tags/Milk.svg";
import { ReactComponent as Eggs } from "../components/Tags/Eggs.svg";
import { ReactComponent as Fish } from "../components/Tags/Fish.svg";
import { ReactComponent as Crustaceans } from "../components/Tags/Crustaceans.svg";
import { ReactComponent as Soybeans } from "../components/Tags/Soybeans.svg";
import { ReactComponent as Nuts } from "../components/Tags/Nuts.svg";
import { ReactComponent as SesameSeeds } from "../components/Tags/SesameSeeds.svg";
import { ReactComponent as Celery } from "../components/Tags/Celery.svg";
import { ReactComponent as Mustard } from "../components/Tags/Mustard.svg";
import { ReactComponent as Mollusks } from "../components/Tags/Mollusks.svg";
import { toast } from "react-toastify";

export default function MenuItemCard({ menuItem }: { menuItem: MenuItem }) {
  const { locationNavigate } = useSelected();
  const { addItem } = useCart();

  const productTags = useMemo(() => menuItem.productInfoTags.map((p) => p.id), [menuItem]);

  const handleAdd = (menuItem: MenuItem) => {
    if (!menuItem.isAvailable) {
      toast.warn(`${menuItem.name} is out of Stock`);
      return;
    }
    if (menuItem.addOnCategories.length === 0) {
      addItem(menuItemToOrderItem(menuItem));
    } else {
      locationNavigate(`/${menuItem.id}`);
    }
  };

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        position: "relative",
        filter: `grayscale(${menuItem.isAvailable ? 0 : 100}%)`,
        overflow: "visible",
      }}
      onClick={() => handleAdd(menuItem)}
    >
      {!menuItem.isAvailable && (
        <Chip
          label="Out of Stock"
          variant="filled"
          color="primary"
          size="small"
          sx={{
            position: "absolute",
            top: -10,
            left: 8,
          }}
        />
      )}
      <CardMedia
        sx={{
          height: 100,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
        image={menuItem.imageUrl}
        title={menuItem.name}
      />
      <CardContent
        sx={{
          p: 1,
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: 700, fontSize: 18 }} variant="h6">
              {menuItem.name}
            </Typography>
            <Typography sx={{ fontWeight: 500, fontSize: 14 }} variant="body2" color="text.secondary">
              {menuItem.shortDescription}
            </Typography>
            <Box sx={{ mt: 1 }}>
              {productTags.includes(1) && <GlutenFree />}
              {productTags.includes(2) && <Spicy />}
              {productTags.includes(3) && <Vegan />}
              {productTags.includes(5) && <Milk />}
              {productTags.includes(6) && <Eggs />}
              {productTags.includes(7) && <Fish />}
              {productTags.includes(8) && <Crustaceans />}
              {productTags.includes(9) && <Soybeans />}
              {productTags.includes(10) && <Nuts />}
              {productTags.includes(11) && <SesameSeeds />}
              {productTags.includes(12) && <Celery />}
              {productTags.includes(13) && <Mustard />}
              {productTags.includes(16) && <Mollusks />}
            </Box>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Button
              onClick={() => handleAdd(menuItem)}
              fullWidth
              color="primary"
              variant="contained"
              disabled={!menuItem.isAvailable}
              sx={{
                borderRadius: 99,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                py: 1,
                px: 2,
              }}
            >
              <Typography color="secondary" sx={{ fontWeight: 500, fontSize: 16 }}>
                CHF <strong>{menuItem.price.toFixed(2)}</strong>
              </Typography>
              <Add color="secondary" />
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
