import { api } from "../axios";
import { Order } from "../_interface/order";
import { OrderItem } from "../_interface/orderItem";
import { PayrexxLinkDTO } from "../_interface/payrexxLink";
import { OrderStatus } from "../_enum/order-status.enum";
import { OrderType } from "../_enum/order-type.enum";

export async function createOrder(
  locationId: number,
  cart: OrderItem[],
  tip: number,
  orderType: OrderType,
  pickupTime?: number
) {
  const { data } = await api.post<PayrexxLinkDTO>(`/order/${locationId}`, {
    order: {
      pickupTime,
      orderType,
      tip,
    },
    orderItems: cart,
  });
  return data;
}
export async function createMemberOrder(
  locationId: number,
  cart: OrderItem[],
  tip: number,
  member: any,
  orderType: OrderType,
  pickupTime?: number
) {
  const { data } = await api.post<PayrexxLinkDTO>(`/order/membership/${locationId}`, {
    order: {
      pickupTime,
      orderType,
      tip,
    },
    orderItems: cart,
    membershipDetails: member,
  });
  return data;
}

export async function getOrderByUser(uid: string): Promise<Order[]> {
  const { data } = await api.get<Order[]>(`/order/user/${uid}`);
  return data;
}

export async function updateStatus(orderId: number, newStatus: OrderStatus): Promise<any> {
  const { data } = await api.put("/order/status", { orderId, newStatus });
  return data;
}

export async function checkPaymentAndUpdateStatusIfSuccessful(orderId: number): Promise<boolean> {
  const { data } = await api.get(`/order/check-payment/${orderId}`);
  return data;
}