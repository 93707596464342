import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES, SEARCH_PARAMS } from "../../config/constants";
import { useUserAuth } from "../../context/AuthContextProvider";
import { useTranslation } from "react-i18next";

export default function AuthPage() {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { user } = useUserAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (user) navigate(`../${ROUTES.menu}`);

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: isLargeScreen ? "flex-start" : "space-between",
        alignItems: "center",
        p: 7.5,
        pb: 9.1,
        mx: "auto",
        background: "#FFF",
        boxSizing: "border-box",
      }}
    >
      <img src="/fastlane_logo_with_text_bottom.png" alt="logo" width={200} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
          mt: 5,
        }}
      >
        <Box textAlign={"center"}>
          <Typography variant="h5" fontWeight={600}>
            {t("common.welcome")}
          </Typography>
          <Typography color={"#575959"} textAlign={"center"} lineHeight={1.3} mt={1.5}>
            {t("auth.description")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Link
            to={`../${ROUTES.signIn}${
              searchParams.get(SEARCH_PARAMS.redirect)
                ? `?${SEARCH_PARAMS.redirect}=${searchParams.get(SEARCH_PARAMS.redirect)}`
                : ""
            }`}
          >
            <Button variant="contained" size="large">
              {t("common.login")}
            </Button>
          </Link>
          <Link
            to={`../${ROUTES.signUp}${
              searchParams.get(SEARCH_PARAMS.redirect)
                ? `?${SEARCH_PARAMS.redirect}=${searchParams.get(SEARCH_PARAMS.redirect)}`
                : ""
            }`}
          >
            <Button variant="outlined" size="large">
              {t("common.signUp")}
            </Button>
          </Link>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} width={"100%"} maxWidth={250} gap={2}>
            <Box width={"100%"} border={1} borderColor={"#00000080"}></Box>
            <Typography color={"#575959"} fontSize={14}>
              {t("common.or")}
            </Typography>
            <Box width={"100%"} border={1} borderColor={"#00000080"}></Box>
          </Box>
          <Link
            to={`../${ROUTES.guestLogin}${
              searchParams.get(SEARCH_PARAMS.redirect)
                ? `?${SEARCH_PARAMS.redirect}=${searchParams.get(SEARCH_PARAMS.redirect)}`
                : ""
            }`}
          >
            <Button variant="contained" size="large">
              {t("auth.continueWithoutAccount")}
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
