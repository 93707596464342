import { useQuery } from "react-query";
import MenuItemCard from "../../components/MenuItemCard";
import { getMenuItemsFromLocation } from "../../services/menu-item.service";
import { MenuItem } from "../../_interface/menuItem";
import { Alert, Chip, Grid, Skeleton, Typography } from "@mui/material";
import BottomBar from "../../components/BottomBar";
import { useSelected } from "../../context/LocationProvider";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Category } from "../../_interface/category";
import DemoAlert from "../../components/Alerts/DemoAlert";
import LanguageSelector from "../../components/LanguageSelector";
import { t } from "i18next";
import { getClientById } from "../../services/client.service";
import { Client } from "../../_interface/location";

const extractCategoriesFromMenuItems = (menuItems: MenuItem[]): Category[] => {
  const categoryMap: Map<number, Category> = new Map();
  for (const i of menuItems) {
    for (const c of i.categories) {
      categoryMap.set(c.id, c);
    }
  }
  return Array.from(categoryMap.values());
};

function SelectionPage() {
  const [categories, setCategories] = useState<Category[]>([]);
  const { selectedLocationId, selectedLocation, selectedClientId } = useSelected();

  const {
    data: menuItems,
    isLoading,
    error,
  } = useQuery<MenuItem[]>(
    ["get-menu-items", { selectedLocationId }],
    async () => await getMenuItemsFromLocation(selectedLocationId!)
  );

  const {
    data: clientDetails,
    isLoading: clientDetailsLoading,
  } = useQuery<Client>(["get-client-by-id", { selectedClientId }], async () => await getClientById(selectedClientId!));

  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(-1);

  useEffect(() => {
    if (menuItems) {
      const allArray: Category[] = [{ id: -1, name: "Alle", arrangmentNr: -1 }];
      const tmpCategories = extractCategoriesFromMenuItems(menuItems);
      setCategories(allArray.concat(tmpCategories));
      setSelectedCategoryId(-1);
    }
  }, [menuItems]);

  const handleChipClick = (selected: number) => {
    setSelectedCategoryId(selected);
  };

  const getMenuItemsFromCategory = (cID: number): MenuItem[] => {
    const foundMenuItems: MenuItem[] = [];
    if (!menuItems) return [];
    for (const item of menuItems) {
      for (const c of item.categories) {
        if (c.id === cID) foundMenuItems.push(item);
      }
    }
    return foundMenuItems;
  };

  function categorySortFn(a: Category, b: Category) {
    if (a.arrangmentNr < b.arrangmentNr) {
      return -1;
    }
    if (a.arrangmentNr > b.arrangmentNr) {
      return 1;
    }
    return 0;
  }

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100%",
        display: "flex",
        backgroundColor: "#F5F5F5",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2} p={2} pb={10} maxHeight={"100%"} overflow={"auto"}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }} position={"relative"}>
          {!clientDetailsLoading && (
            <img
              src={clientDetails?.logoUrl || "/fastlane_logo_with_text.png"}
              alt="Fastlane Logo"
              style={{ maxHeight: 40 }}
            />
          )}
          <LanguageSelector />
        </Grid>
        <Grid item xs={12}>
          {selectedLocation && selectedLocation.isDemo && <DemoAlert />}
          {error !== null && <Alert severity="error">{t("error.tryLater")}</Alert>}
          {isLoading && <Skeleton height={500} />}
        </Grid>
        {categories && (
          <>
            <Grid
              item
              xs={12}
              display={"flex"}
              overflow={"auto"}
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {categories
                .sort((a, b) => categorySortFn(a, b))
                .map((c: Category) => (
                  <Chip
                    key={c.id}
                    color={selectedCategoryId === c.id ? "primary" : "secondary"}
                    variant={selectedCategoryId === c.id ? "filled" : "outlined"}
                    label={c.name}
                    sx={{ mr: 1 }}
                    onClick={() => handleChipClick(c.id)}
                  />
                ))}
            </Grid>

            {selectedCategoryId === -1 ? (
              categories.map(
                (c: Category) =>
                  c.id !== -1 && (
                    <>
                      <Grid item xs={12} mt={2}>
                        <Typography sx={{ fontWeight: 700, fontSize: 20 }}>{c.name}</Typography>
                      </Grid>
                      {getMenuItemsFromCategory(c.id).map((item) => (
                        <Grid key={item.id} item xs={6} md={4} lg={3}>
                          <MenuItemCard menuItem={item} />
                        </Grid>
                      ))}
                    </>
                  )
              )
            ) : (
              <>
                <Grid item xs={12} mt={2}>
                  <Typography sx={{ fontWeight: 700, fontSize: 20 }}>
                    {categories.find((cat: Category) => cat.id === selectedCategoryId)?.name}
                  </Typography>
                </Grid>
                {getMenuItemsFromCategory(selectedCategoryId).map((item) => (
                  <Grid key={item.id} item xs={6} md={4} lg={3}>
                    <MenuItemCard menuItem={item} />
                  </Grid>
                ))}
              </>
            )}
          </>
        )}
      </Grid>
      <BottomBar />
    </Box>
  );
}

export default SelectionPage;
