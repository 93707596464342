import { Box, Button, FormControl, Theme, Typography, useMediaQuery } from "@mui/material";
import BackButton from "../../components/BackButton";
import { FormEvent, useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUserAuth } from "../../context/AuthContextProvider";
import { ROUTES, SEARCH_PARAMS } from "../../config/constants";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface VerifyOtpFormData {
  otp: string;
}

const emptyVerifyOtpFormData: VerifyOtpFormData = {
  otp: "",
};

export default function VerifyOtpPage() {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [data, setData] = useState<VerifyOtpFormData>(emptyVerifyOtpFormData);
  const [error, setError] = useState<VerifyOtpFormData>(emptyVerifyOtpFormData);
  const { user, verifyOTP } = useUserAuth();
  const [resendOtpEnabled, setResendOtpEnabled] = useState(false);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (user && user.phoneNumber) {
    console.log("user logged in with phone number");
    navigate(`../${ROUTES.menu}`);
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (data.otp.trim() === "" || data.otp.length < 6) {
      setError((prev) => ({
        ...prev,
        otp: t("error.required.otp"),
      }));
      return;
    }
    console.log("submitting verify otp form...");
    try {
      await verifyOTP(
        data.otp,
        searchParams.get(SEARCH_PARAMS.process) === ROUTES.guestLogin ? ROUTES.guestLogin : ROUTES.signUp
      );
      if (searchParams.get(SEARCH_PARAMS.redirect) && searchParams.get(SEARCH_PARAMS.redirect) !== "null")
        navigate(`../${searchParams.get(SEARCH_PARAMS.redirect)}`);
      else navigate(`../${ROUTES.menu}`);
    } catch (error) {
      console.log(error);
    }
    console.log("verify otp form submitted!");
  };

  const resendOTP = async () => {
    // await getOTP(userPhoneNumber);
    setResendOtpEnabled(false);
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: isLargeScreen ? "flex-start" : "space-between",
        alignItems: "center",
        background: "#FFF",
        boxSizing: "border-box",
        gap: 5,
        px: 6,
        pt: 4,
        pb: 9.1,
        mx: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box width={"100%"}>
          <BackButton sx={{ ml: -2 }} />
        </Box>
        <Typography variant="h4" width={"100%"}>
          {t("verifyOtp.heading")}
        </Typography>
        <FormControl sx={{ position: "relative" }}>
          <Typography color={"#9796A1"} fontSize={14} width={"100%"} mb={2}>
            {t("verifyOtp.description")}
          </Typography>
          <MuiOtpInput
            length={6}
            value={data.otp}
            onChange={(newValue) => {
              if (error.otp) setError((prev) => ({ ...prev, otp: "" }));
              setData((prev) => ({ ...prev, otp: newValue }));
            }}
            sx={{
              gap: 1.5,
            }}
            TextFieldsProps={{
              sx: {
                "& input": {
                  padding: 1.5,
                },
              },
            }}
          />
          {error.otp && (
            <Typography color={"error"} fontSize={12} position={"absolute"} top={"100%"}>
              {error.otp}
            </Typography>
          )}
        </FormControl>
        <Typography color={"#5B5B5E"} width={"100%"} mt={5} fontSize={14}>
          {t("verifyOtp.didntReciveCode")}{" "}
          {resendOtpEnabled ? (
            <Typography
              component={"span"}
              color="primary.main"
              fontSize={"inherit"}
              fontWeight={600}
              onClick={resendOTP}
            >
              {t("verifyOtp.resendOtp")}
            </Typography>
          ) : (
            <Countdown
              date={dayjs().add(30, "seconds").toString()}
              renderer={({ minutes, seconds }) => (
                <Typography component={"span"} color={"#5B5B5E33"} fontSize={"inherit"}>
                  {t("verifyOtp.resendIn")} {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}
                </Typography>
              )}
            ></Countdown>
          )}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2.5,
        }}
      >
        <Button variant="contained" type="submit" size="large">
          {t("common.confirm")}
        </Button>
      </Box>
    </Box>
  );
}
