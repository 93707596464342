import { api } from "../axios";
import { MenuItem } from "../_interface/menuItem";
import { MenuItemAttribute } from "../_interface/menuItemAttribute";
import { OrderItem } from "../_interface/orderItem";

// has some on location 3
export async function getMenuItemsFromLocation(
  locationId: number
): Promise<MenuItem[]> {
  const { data } = await api.get<MenuItem[]>(
    `/menu-item/location/${locationId}`
  );
  return data;
}

export async function getMenuItemByID(menuItemId: number): Promise<MenuItem> {
  const { data } = await api.get<MenuItem>(`/menu-item/${menuItemId}`);
  return data;
}

export async function getMenuItemAttributesByID(
  menuItemId: number
): Promise<MenuItemAttribute[]> {
  const { data } = await api.get<MenuItemAttribute[]>(
    `/menu-item-attributes/${menuItemId}`
  );
  return data;
}

export function menuItemToOrderItem(item: MenuItem, quantity = 0): OrderItem {
  return {
    quantity: 1,
    //   selectedAttributes: [],
    selectedMenuItemAddOns: [],
    hash: "",
    pricePerQuantity: item.price,
    totalPrice: 0,
    menuItem: item,
  };
}
