import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SEARCH_PARAMS } from "../../config/constants";


function PaymentCanceledPage() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
      <Box
        sx={{
          height: "100%",
          minHeight: "100%",
          maxWidth: 500,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 5,
          px: 6,
          pt: 4,
          pb: 9.1,
          mx: "auto",
          background: "#FFF",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box maxWidth={180}>
            <img src="/failed.png" alt="failed" />
          </Box>
          <Typography fontSize={20} fontWeight={600} textAlign={"center"}>
            {searchParams.get(SEARCH_PARAMS.demo) && `${t("common.demo")} `}
            {t("canceled.orderCanceled")}
          </Typography>
        </Box>
        <Button
          variant="contained"
          size="large"
          sx={{
            position: "absolute",
            bottom: 0,
            mb: 9.1,
          }}
          onClick={() => navigate("../")}
        >
          {t("canceled.menuPage")}
        </Button>
      </Box>
    );
}

export default PaymentCanceledPage;