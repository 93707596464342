import { useEffect, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useSelected } from "./context/LocationProvider";
import NoServiceModal from "./modals/NoServiceModal";
import dayjs from "dayjs";

const getTime = (time: string | null) => {
  if (!time) return null;
  const [hours, minutes] = time.split(":");
  return dayjs().hour(parseInt(hours)).minute(parseInt(minutes));
};

const isWorkingHours = (_startTime: string | null, _endTime: string | null) => {
  const startTime = getTime(_startTime);
  const endTime = getTime(_endTime);
  const currentTime = dayjs();
  return (
    (currentTime.isSame(startTime) || currentTime.isAfter(startTime)) &&
    (currentTime.isSame(endTime) || currentTime.isBefore(endTime))
  );
};

export default function Body() {
  const { clientId, locationId } = useParams();
  const { selectedClientId, selectedLocationId, selectedLocation, updateSelectedClientId, updateSelectedLocationId } =
    useSelected();

  useEffect(() => {
    if (clientId) updateSelectedClientId(parseInt(clientId));
    if (locationId) updateSelectedLocationId(parseInt(locationId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, locationId]);
  // console.log(selectedLocation);

  const noService = useMemo(() => {
    let day = dayjs().day();
    if (day) day--;
    else day = 6;
    if (
      !selectedLocation?.settings?.isOnlineServiceEnabled ||
      !selectedLocation.settings?.workingDays[day].isWorkingDay
    )
      return true;
    const workHours = selectedLocation?.settings?.workingDays[day].workHours;
    for (const { startTime, endTime } of workHours!) {
      if (isWorkingHours(startTime, endTime)) return false;
    }
    return true;
  }, [selectedLocation?.settings]);

  const deactivationMsg = useMemo(() => {
    if (!selectedLocation?.settings?.isOnlineServiceEnabled) return selectedLocation?.settings?.deActivationMsg;
    let day = dayjs().day();
    if (day) day--;
    else day = 6;
    if (!selectedLocation.settings?.workingDays[day].isWorkingDay) return `Sorry, it's not a working day.`;
    return `Sorry, these are not working hours.`;
  }, [selectedLocation?.settings]);

  return selectedClientId && selectedLocationId ? (
    <>
      <Outlet />
      {selectedLocation && noService && <NoServiceModal deActivationMsg={deactivationMsg} />}
    </>
  ) : (
    <></>
  );
}
