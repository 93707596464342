import { Box, Button, Grid, IconButton, Typography } from "@mui/material";

import { KeyboardArrowLeft } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES, SEARCH_PARAMS } from "../../config/constants";
import { useTranslation } from "react-i18next";

function OrderProcessPage(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const handleRedirect = async () => {
    // if redirect is passed, then goto auth page with redirect param.
    // /auth?redirect={passed redirect}
    // else goto auth page without redirect param.

    if (searchParams.get(SEARCH_PARAMS.redirect))
      navigate(`../${ROUTES.auth}?${SEARCH_PARAMS.redirect}=${searchParams.get(SEARCH_PARAMS.redirect)}`);
    else navigate(`../${ROUTES.auth}`);
  };

  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <IconButton size="small" sx={{ border: 1, color: "#767F9D" }} onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
            </IconButton>
            <Typography sx={{ fontSize: 24, fontWeight: 700, ml: 1 }}>{t("process.heading")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 16, fontWeight: 500, ml: 1 }}>{t("process.description")}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ flexGrow: 1, pr: 3 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
                  1. {t("process.specifyPickUpTime.heading")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7E8392",
                    fontWeight: 500,
                  }}
                >
                  {t("process.specifyPickUpTime.description")}
                </Typography>
              </Box>
              <img
                src="/process/time.svg"
                alt="verify phone number"
                style={{
                  height: 100,
                  width: 100,
                  borderRadius: 8,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ flexGrow: 1, pr: 3 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
                  2. {t("process.verifyPhoneNumber.heading")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7E8392",
                    fontWeight: 500,
                  }}
                >
                  {t("process.verifyPhoneNumber.description")}
                </Typography>
              </Box>
              <img
                src="/process/verify.svg"
                alt="verify phone number"
                style={{
                  height: 100,
                  width: 100,
                  borderRadius: 8,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ flexGrow: 1, pr: 3 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>3. {t("process.paySafely.heading")}</Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7E8392",
                    fontWeight: 500,
                  }}
                >
                  {t("process.paySafely.description")}
                </Typography>
              </Box>
              <img
                src="/process/pay.svg"
                alt="verify phone number"
                style={{
                  height: 100,
                  width: 100,
                  borderRadius: 8,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ flexGrow: 1, pr: 3 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>
                  4. {t("process.preparingYourMeals.heading")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7E8392",
                    fontWeight: 500,
                  }}
                >
                  {t("process.preparingYourMeals.description")}
                </Typography>
              </Box>
              <img
                src="/process/prepare.svg"
                // src='/process/notifications.svg'
                alt="verify phone number"
                style={{
                  height: 100,
                  width: 100,
                  borderRadius: 8,
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ flexGrow: 1, pr: 3 }}>
                <Typography sx={{ fontSize: 18, fontWeight: 700 }}>5. {t("process.bonAppetite.heading")}</Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#7E8392",
                    fontWeight: 500,
                  }}
                >
                  {/* {t("process.bonAppetite.description")} */}
                </Typography>
              </Box>
              <img
                src="/process/pickup.svg"
                alt="verify phone number"
                style={{
                  height: 100,
                  width: 100,
                  borderRadius: 8,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ p: 1 }}>
        <Button
          variant="contained"
          onClick={handleRedirect}
          color="primary"
          size="large"
          fullWidth
          sx={{ borderRadius: 8 }}
        >
          {t("common.continue")}
        </Button>
      </Box>
    </Box>
  );
}

export default OrderProcessPage;
