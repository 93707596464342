import { Alert, Backdrop, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useCart } from "../../context/CartProvider";
import { useSelected } from "../../context/LocationProvider";
import { createOrder } from "../../services/order.service";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { ROUTES, SEARCH_PARAMS } from "../../config/constants";
import { useTranslation } from "react-i18next";

function OrderPaymentModePage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [errorAlert, setErrorAlert] = useState<JSX.Element>();
  const { selectedLocationId, selectedLocation } = useSelected();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { cart, tip, pickupTime, orderType } = useCart();

  const handleOrder = async () => {
    setLoading(true);
    if (!selectedLocationId) throw Error("No Location ID set cant create a Order");
    // dont continue to checkout when location is in demo mode
    if (selectedLocation && selectedLocation.isDemo) {
      navigate(`./${ROUTES.success}?${SEARCH_PARAMS.demo}=true`, {
        replace: true,
      });
      return;
    }

    const res = await createOrder(selectedLocationId, cart, tip, orderType, pickupTime.unix() * 1000);
    if (res.isSuccessful) {
      setLoading(false);
      window.location.replace(res.link);
    } else {
      setErrorAlert(<Alert severity="error">{t("error.creatingOrder")}</Alert>);
    }
    setLoading(false);
    return;
  };

  return (
    <Box
      bgcolor={"#EAF3FA"}
      sx={{
        height: "100%",
        minHeight: "100%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <IconButton size="small" sx={{ border: 1, color: "#767F9D" }} onClick={() => navigate(-1)}>
              <KeyboardArrowLeft />
            </IconButton>
            <Typography sx={{ fontSize: 24, fontWeight: 700, ml: 1 }}>{t("common.paymentOption")}</Typography>
          </Grid>

          <Grid item xs={12} mt={2}>
            {errorAlert}
          </Grid>

          <Grid item xs={12}>
            <Box
              bgcolor={"white"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              height={50}
              width={"100%"}
              borderRadius={0.5}
              onClick={handleOrder}
              px={2}
              py={4}
              boxSizing={"border-box"}
            >
              <Box display={"flex"} justifyContent={"center"} alignItems={"center"} pl={4} gap={4}>
                <img src="/visa.png" alt="visa" />
                <img src="/masterCard.png" alt="mastercard" />
              </Box>
              <IconButton size="small" sx={{ border: 1, color: "#767F9D" }}>
                <KeyboardArrowRight />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Backdrop sx={{ color: "#fff", zIndex: 99 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  );
}

export default OrderPaymentModePage;
