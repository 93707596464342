import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import BackButton from "../../components/BackButton";
import { FormEvent, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCart } from "../../context/CartProvider";
import BottomBar from "../../components/BottomBar";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../config/constants";
import { useTranslation } from "react-i18next";

enum TipMode {
  NoTip = "Kein Trinkgeld",
  Auto = "Auto",
  Percentage = "%",
  CHF = "CHF",
}

interface TipData {
  mode: TipMode;
  amount: number;
}

const defaultTipPercentage = 5;

const emptyForgotPasswordFormData: TipData = {
  mode: TipMode.Percentage,
  amount: defaultTipPercentage,
};

const activeTabProps = (isActive: boolean) =>
  isActive
    ? {
        bgcolor: "primary.main",
        color: "#fff",
      }
    : {};

export default function TipPage() {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const [data, setData] = useState<TipData>(emptyForgotPasswordFormData);
  const [error, setError] = useState("");
  const [tab, setTab] = useState<number>(2);
  const { tip, setTip, subTotal, total } = useCart();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("submitting tip form...");
    if (tip < 0) {
      return setError("Tip cannot be less than 0");
    }
    navigate(`../${ROUTES.paymentMode}`);
    console.log("tip form submitted!");
  };

  const handleTabChange = (index: number) => {
    setTab(index);
    const mode = Object.values(TipMode)[index];
    if (mode === TipMode.NoTip) {
      setData({
        mode,
        amount: 0,
      });
    } else if (mode === TipMode.CHF) {
      setData({
        mode,
        amount: Number(((subTotal * defaultTipPercentage) / 100).toFixed(2)),
      });
    } else {
      setData({
        mode,
        amount: defaultTipPercentage,
      });
    }
  };

  useMemo(() => {
    if (data.mode === TipMode.Percentage) {
      setTip((subTotal * data.amount) / 100);
    } else setTip(data.amount);
  }, [data, subTotal, setTip]);

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: isLargeScreen ? "flex-start" : "space-between",
        alignItems: "center",
        background: "#FFF",
        boxSizing: "border-box",
        gap: 5,
        px: 4.9,
        pt: 4,
        pb: 9.1,
        mx: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box width={"100%"}>
          <BackButton sx={{ ml: -2 }} />
        </Box>
        <Typography variant="h4" width={"100%"}>
          {t("common.tip")}
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "auto auto auto auto",
            backgroundColor: "#fff",
            border: 1,
            borderColor: "primary.main",
            borderRadius: 0.8,
            cursor: "pointer",
          }}
        >
          {Object.values(TipMode).map((mode, index) => (
            <Box
              key={index}
              py={1.5}
              borderRight={1}
              borderColor={"primary.main"}
              textAlign={"center"}
              height={"100%"}
              sx={{
                transition: "all 0.3s",
                "&:first-of-type": { borderTopLeftRadius: "inherit", borderBottomLeftRadius: "inherit" },
                "&:last-child": {
                  borderTopRightRadius: "inherit",
                  borderBottomRightRadius: "inherit",
                  borderRight: 0,
                },
              }}
              {...activeTabProps(tab === index)}
              onClick={() => handleTabChange(index)}
            >
              {mode}
            </Box>
          ))}
        </Box>
        {(data.mode === TipMode.CHF || data.mode === TipMode.Percentage) && (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <Button
              variant="outlined"
              sx={{ minWidth: 0, p: 0.7 }}
              onClick={() =>
                setData((prev) => {
                  if (prev.amount - 1 < 0) return { ...prev, amount: 0 };
                  else return { ...prev, amount: prev.amount - 1 };
                })
              }
            >
              <RemoveIcon fontSize="medium" />
            </Button>
            <Typography>
              <input
                value={data.amount}
                type="number"
                style={{
                  outline: "none",
                  border: "none",
                  maxWidth: 10 + 10 * data.amount.toString().length,
                  fontSize: 16,
                  textAlign: "right",
                  padding: 0,
                }}
                onChange={(e) => setData((prev) => ({ ...prev, amount: parseFloat(e.target.value || "0") }))}
              />{" "}
              {data.mode}
            </Typography>
            <Button
              variant="contained"
              sx={{ minWidth: 0, p: 0.7 }}
              onClick={() => setData((prev) => ({ ...prev, amount: prev.amount + 1 }))}
            >
              <AddIcon fontSize="medium" />
            </Button>
          </Box>
        )}
        {error && <Typography color={"error"}>{`${error}`}</Typography>}
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2.5,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} mb={0.5}>
            <Typography fontSize={14}>{t("common.subTotal")}</Typography>
            <Typography fontSize={14}>CHF {subTotal.toFixed(2)}</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} mb={0.5}>
            <Typography fontSize={14}>{t("common.tip")}</Typography>
            <Typography fontSize={14}>
              {data.mode === TipMode.Percentage && (
                <span>
                  ({data.amount}
                  {TipMode.Percentage})
                </span>
              )}{" "}
              CHF {tip.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} mb={0.5}>
          <Typography variant="h5" fontWeight={600}>
            {t("common.total")}
          </Typography>
          <Typography variant="h5" fontWeight={600}>
            CHF {total.toFixed(2)}
          </Typography>
        </Box>
        <Button variant="contained" type="submit" size="large">
          {t("common.continue")}
        </Button>
        <BottomBar />
      </Box>
    </Box>
  );
}
