import { Fastfood, Person, ShoppingCart } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Box } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useUserAuth } from "../context/AuthContextProvider";
import { useCart } from "../context/CartProvider";
import { ROUTES } from "../config/constants";
import { useTranslation } from "react-i18next";

function BottomBar() {
  const { user } = useUserAuth();
  const { newItem, setNewItem } = useCart();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <BottomNavigation sx={{ position: "fixed", zIndex: 90, bottom: 0, left: 0, right: 0 }} showLabels={true}>
        <BottomNavigationAction
          color="primary"
          label={t("common.menu")}
          icon={<Fastfood />}
          component={Link}
          to={pathname.split("/")[3] ? `../${ROUTES.menu}` : `./${ROUTES.menu}`}
        />
        <BottomNavigationAction
          color="primary"
          label={t("common.cart")}
          icon={
            <>
              <ShoppingCart />
              <Box
                sx={{
                  position: "absolute",
                  top: 2,
                  left: "58%",
                  width: 10,
                  height: 10,
                  borderRadius: "50%",
                  backgroundColor: "#f00",
                  display: newItem ? "block" : "none",
                }}
              ></Box>
            </>
          }
          component={Link}
          // to={`/${selectedClientId}/${selectedLocationId}/order/`}
          to={pathname.split("/")[3] ? `../${ROUTES.cart}` : `./${ROUTES.cart}`}
          sx={{ position: "relative" }}
          onClick={() => setNewItem(false)}
        />
        {user ? (
          <BottomNavigationAction
            color="primary"
            label={t("common.profile")}
            icon={<Person />}
            component={Link}
            to={pathname.split("/")[3] ? `../${ROUTES.profile}` : `./${ROUTES.profile}`}
          />
        ) : null}
      </BottomNavigation>
    </>
  );
}

export default BottomBar;
