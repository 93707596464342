import { Box, Typography } from "@mui/material";
import CartItemQuantityButtons from "./CartItemQuantityButtons";
import { MenuItem } from "../_interface/menuItem";
import { MenuItemAddOn } from "../_interface/addOn";

interface Props {
  item: MenuItem;
  addOns: MenuItemAddOn[];
  quantity: number;
  totalPrice: number;
  index: number;
}

export default function CartItem({ item, addOns, quantity, totalPrice, index }: Props) {
  return (
    <Box
      display={"grid"}
      gridTemplateColumns={"auto auto"}
      px={4}
      py={2}
      width={"100%"}
      borderTop={1}
      borderColor={"#2a9ef757"}
    >
      <Box>
        <Typography fontSize={16} fontWeight={600}>
          {item.name}
        </Typography>
        {addOns && (
          <ul
            style={{
              fontSize: 14,
              color: "#8C8A9D",
              paddingInlineStart: 16,
              margin: 0,
              marginTop: 4,
            }}
          >
            {addOns.map((addOn) => (
              <li>{addOn.name}</li>
            ))}
          </ul>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} alignItems={"flex-end"} gap={2}>
        <Typography fontSize={15} fontWeight={600} color={"primary.main"}>
          CHF {totalPrice.toFixed(2)}
        </Typography>
        <CartItemQuantityButtons quantity={quantity} index={index} name={item.name} />
      </Box>
    </Box>
  );
}
