import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import OutlinedLabeledInput from "../../components/OutlinedLabeledInput";
import { FormEvent, useState } from "react";
import { ROUTES, SEARCH_PARAMS } from "../../config/constants";
import { useUserAuth } from "../../context/AuthContextProvider";
import { useTranslation } from "react-i18next";
import TelOutlinedLabeledInput from "../../components/TelOutlinedLabeledInput";

interface SignUpFormData {
  firstName: string;
  lastName: string;
  email: string;
  phoneNo: string;
  password: string;
}

const emptySignUpFormData: SignUpFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNo: "",
  password: "",
};

export default function SignUpPage() {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { user, signUp } = useUserAuth();
  const [data, setData] = useState<SignUpFormData>(emptySignUpFormData);
  const [error, setError] = useState<SignUpFormData>(emptySignUpFormData);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (user && user.phoneNumber) navigate(`../${ROUTES.menu}`);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (data.firstName.trim() === "") {
      setError((prev) => ({ ...prev, firstName: t("error.required.firstName") }));
    }
    if (data.lastName.trim() === "") {
      setError((prev) => ({ ...prev, lastName: t("error.required.lastName") }));
    }
    if (data.phoneNo.trim() === "") {
      setError((prev) => ({
        ...prev,
        email: t("error.required.email"),
      }));
    }
    if (data.phoneNo.trim() === "") {
      setError((prev) => ({
        ...prev,
        phoneNo: t("error.required.phoneNumber"),
      }));
    }
    if (data.password.length < 7) {
      setError((prev) => ({
        ...prev,
        password: t("error.required.password"),
      }));
    }
    if (
      data.firstName.trim() === "" ||
      data.lastName.trim() === "" ||
      data.email.trim() === "" ||
      data.phoneNo.trim() === "" ||
      data.password.length < 7
    )
      return;
    console.log("submitting sign up form...");
    await signUp(data.email, data.password, data.firstName, data.lastName, data.phoneNo);

    // if (searchParams.get(SEARCH_PARAMS.redirect)) navigate(`../${searchParams.get(SEARCH_PARAMS.redirect)}`);
    // else navigate(`../${ROUTES.menu}`);
    console.log("navigate to verify");

    if (searchParams.get(SEARCH_PARAMS.redirect))
      navigate(
        `../${ROUTES.verifyOtp}?${SEARCH_PARAMS.redirect}=${searchParams.get(SEARCH_PARAMS.redirect)}&${
          SEARCH_PARAMS.process
        }=${ROUTES.signUp}`
      );
    else navigate(`../${ROUTES.verifyOtp}?${SEARCH_PARAMS.process}=${searchParams.get(SEARCH_PARAMS.process)}`);
    console.log("sign up form submitted!");
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: isLargeScreen ? "flex-start" : "space-between",
        alignItems: "center",
        gap: 5,
        px: 6,
        py: 4,
        mx: "auto",
        background: "#FFF",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box width={"100%"}>
          <BackButton sx={{ ml: -2 }} />
        </Box>
        <Typography variant="h4" width={"100%"}>
          {t("common.signUp")}
        </Typography>
        <OutlinedLabeledInput
          label={t("common.firstName")}
          name="First name"
          fullWidth
          value={data.firstName}
          onChange={(e) => {
            if (error.firstName) setError((prev) => ({ ...prev, firstName: "" }));
            setData((prev) => ({ ...prev, firstName: e.target.value }));
          }}
          error={!!error.firstName}
          helperText={error.firstName}
        />
        <OutlinedLabeledInput
          label={t("common.lastName")}
          name="Last name"
          fullWidth
          value={data.lastName}
          onChange={(e) => {
            if (error.lastName) setError((prev) => ({ ...prev, lastName: "" }));
            setData((prev) => ({ ...prev, lastName: e.target.value }));
          }}
          error={!!error.lastName}
          helperText={error.lastName}
        />
        <OutlinedLabeledInput
          label={t("common.email")}
          name="Email"
          fullWidth
          value={data.email}
          onChange={(e) => {
            if (error.email) setError((prev) => ({ ...prev, email: "" }));
            setData((prev) => ({ ...prev, email: e.target.value }));
          }}
          error={!!error.email}
          helperText={error.email}
        />
        <TelOutlinedLabeledInput
          label={t("common.phoneNumber")}
          name="Phone No"
          fullWidth
          value={data.phoneNo}
          onChange={(newValue) => {
            if (error.phoneNo) setError((prev) => ({ ...prev, phoneNo: "" }));
            setData((prev) => ({ ...prev, phoneNo: newValue }));
          }}
          error={!!error.phoneNo}
          helperText={error.phoneNo}
        />
        <OutlinedLabeledInput
          label={t("common.password")}
          name="Password"
          type="password"
          fullWidth
          value={data.password}
          onChange={(e) => {
            if (error.password) setError((prev) => ({ ...prev, password: "" }));
            setData((prev) => ({ ...prev, password: e.target.value }));
          }}
          error={!!error.password}
          helperText={error.password}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2.5,
        }}
      >
        <Button variant="contained" type="submit" size="large">
          {t("common.signUp")}
        </Button>
        <Typography color={"#575959"} fontSize={14}>
          {t("auth.alreadyHaveAccount")}{" "}
          <Link
            replace
            to={`../${ROUTES.signIn}?${SEARCH_PARAMS.redirect}=${searchParams.get(SEARCH_PARAMS.redirect)}`}
            color="primary.main"
          >
            {t("common.login")}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
