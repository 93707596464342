import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyAmbtzlPDFEF2xgAg1zKT6qq96sM31D_G0",
  authDomain: "fastlane-a868b.firebaseapp.com",
  projectId: "fastlane-a868b",
  storageBucket: "fastlane-a868b.appspot.com",
  messagingSenderId: "392676554874",
  appId: "1:392676554874:web:cf756567a8ed8e3e2e63c3",
};

const app = firebase.initializeApp(firebaseConfig);
export const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6Lc48QgpAAAAAFHOAUQrjewKj3k5M5gZdXcUgjGZ"),
  isTokenAutoRefreshEnabled: true,
});
export const auth = getAuth(app);
export default app;
