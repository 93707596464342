import { Box, Typography } from "@mui/material";

export default function NoServiceModal({ deActivationMsg }: { deActivationMsg: string | undefined }) {
  return (
    <Box
      position={"fixed"}
      top={0}
      left={0}
      width={"100%"}
      height={"100%"}
      bgcolor={"rgba(0, 0, 0, 0.48)"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      px={2}
      zIndex={100}
    >
      <Box
        position={"relative"}
        bgcolor={"background.paper"}
        borderRadius={1.5}
        maxWidth={500}
        minWidth={300}
        onClick={(e) => e.stopPropagation()}
        p={4}
        display={"flex"}
        flexDirection={"column"}
        gap={2}
      >
        <Typography variant="h5" color={"primary.main"}>
          No Service
        </Typography>
        {deActivationMsg ? (
          <Typography color={"#767676"}>{deActivationMsg}</Typography>
        ) : (
          <>
            <Typography color={"#767676"}>Due to heavy load, we are not attending online orders right now.</Typography>
            <Typography color={"#767676"}>Sorry for inconvinence!</Typography>
          </>
        )}
      </Box>
    </Box>
  );
}
