import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import OutlinedLabeledInput from "../../components/OutlinedLabeledInput";
import { FormEvent, useState } from "react";
import { ROUTES, SEARCH_PARAMS } from "../../config/constants";
import { useUserAuth } from "../../context/AuthContextProvider";
import { useTranslation } from "react-i18next";

interface SignInFormData {
  email: string;
  password: string;
}

const emptySignInFormData: SignInFormData = {
  email: "",
  password: "",
};

export default function SignInPage() {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const { user, signInWithMail } = useUserAuth();
  const [data, setData] = useState<SignInFormData>(emptySignInFormData);
  const [error, setError] = useState<SignInFormData>(emptySignInFormData);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (user) navigate(`../${ROUTES.menu}`);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (data.email.trim() === "") {
      setError((prev) => ({
        ...prev,
        email: t("error.required.phoneNumber"),
      }));
    }
    if (data.password.length < 7) {
      setError((prev) => ({
        ...prev,
        password: t("error.required.password"),
      }));
    }
    if (data.email.trim() === "" || data.password.length < 7) return;
    console.log("submitting login form...");
    await signInWithMail(data.email, data.password);
    if (searchParams.get(SEARCH_PARAMS.redirect) && searchParams.get(SEARCH_PARAMS.redirect) !== "null")
      navigate(`../${searchParams.get(SEARCH_PARAMS.redirect)}`);
    else navigate(`../${ROUTES.menu}`);
    console.log("login form submitted!");
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        height: "100%",
        minHeight: "100%",
        maxWidth: 500,
        display: "flex",
        flexDirection: "column",
        justifyContent: isLargeScreen ? "flex-start" : "space-between",
        alignItems: "center",
        gap: 5,
        px: 6,
        py: 4,
        mx: "auto",
        background: "#FFF",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Box width={"100%"}>
          <BackButton sx={{ ml: -2 }} />
        </Box>
        <Typography variant="h4" width={"100%"}>
          {t("common.login")}
        </Typography>
        <OutlinedLabeledInput
          label={t("common.email")}
          name="Email"
          fullWidth
          value={data.email}
          onChange={(e) => {
            if (error.email) setError((prev) => ({ ...prev, email: "" }));
            setData((prev) => ({ ...prev, email: e.target.value }));
          }}
          error={!!error.email}
          helperText={error.email}
        />
        <OutlinedLabeledInput
          label={t("common.password")}
          name="Password"
          type="password"
          fullWidth
          value={data.password}
          onChange={(e) => {
            if (error.password) setError((prev) => ({ ...prev, password: "" }));
            setData((prev) => ({ ...prev, password: e.target.value }));
          }}
          error={!!error.password}
          helperText={error.password}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: 2.5,
        }}
      >
        <Typography color={"#575959"} fontSize={14}>
          <Link
            to={`../${ROUTES.forgotPassword}?${SEARCH_PARAMS.redirect}=${searchParams.get(SEARCH_PARAMS.redirect)}`}
            color="primary.main"
          >
            {t("common.forgotPassword")}?
          </Link>
        </Typography>
        <Button variant="contained" type="submit" size="large">
          {t("common.login")}
        </Button>
        <Typography color={"#575959"} fontSize={14}>
          {t("auth.dontHaveAccount")}{" "}
          <Link
            replace
            to={`../${ROUTES.signUp}?${SEARCH_PARAMS.redirect}=${searchParams.get(SEARCH_PARAMS.redirect)}`}
            color="primary.main"
          >
            {t("common.signUp")}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
}
