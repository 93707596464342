export const ROUTES = {
  menu: "",
  auth: "auth",
  signUp: "sign-up",
  signIn: "sign-in",
  forgotPassword: "forgot-password",
  guestLogin: "guest-login",
  verifyOtp: "verify-otp",
  cart: "cart",
  paymentMode: "payment-mode",
  profile: "profile",
  process: "process",
  tip: "tip",
  success: ":orderId/success",
  failed: ":orderId/failed",
  canceled: ":orderId/canceled",
  home: "",
  menuItemId: ":menuItemId",
  clientId: ":clientId",
  locationId: ":locationId",
};

export const SEARCH_PARAMS = {
  redirect: "redirect",
  demo: "demo",
  process: "process",
};
